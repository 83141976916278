<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import { required} from "vuelidate/lib/validators";
import Master from "@/apis/Master";
import Multiselect from "vue-multiselect";
import Vue from "vue";
/**
 * Orders Component
 */
export default {
  components: {
    Multiselect,
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "PRODUCT SPECIFICATION FILTER EDIT",
      items: [
        {
          text: "DASHBOARD",
          href: "/"
        },
        {
          text: "PRODUCT SPECIFICATION FILTERS",
          href:"/master-data/product-specification-filter"
        },
        {
          text: "PRODUCT SPECIFICATION FILTERS EDIT",
        }
      ],

      products: {
        subMenu: "",
        specification: [],
        filter: [],
      },
      submitted: false,
      editSubmitted: false,
      showmodal: false,
      listSubCategoryData: [],
      listSubmenuFiltersAndSpecificationData: [],
      details:[],
      specificationData: [],
      filterData: [],
      successMessage: "",
      errorMessage: "",
      errorCodeMessage: "",
      editshowmodal: false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
  },
  mounted() {
    // Set the initial number of items
    Master.listSubmenuFiltersAndSpecificationList().then((res) => {
      console.log(res)
      this.listSubmenuFiltersAndSpecificationData = res.data.data;
      for (var i = 0; i <= this.listSubmenuFiltersAndSpecificationData.length; i++){
        if (this.listSubmenuFiltersAndSpecificationData[i].id == localStorage.getItem('listSubMenuId')){
          this.products.subMenu = {id: this.listSubmenuFiltersAndSpecificationData[i].id, name: this.listSubmenuFiltersAndSpecificationData[i].name}
          this.products.specification = this.listSubmenuFiltersAndSpecificationData[i].specifications.map(({specificationId}) => specificationId)
          this.products.filter = this.listSubmenuFiltersAndSpecificationData[i].filters.map(({filterId}) => filterId)
        }
      }



    })



    Master.listSubCategoryList({
      trash: 0,
      subCategoryId: 0
    }).then((res) => {
      console.log(res)
      this.listSubCategoryData = res.data.data;

    });

    Master.specificationList({
      trash: 0
    }).then((res) => {
      console.log(res)
      this.specificationData = res.data.data;
    })

    Master.filterList({
      trash: 0
    }).then((res) => {
      console.log(res)
      this.filterData = res.data.data;
    })

  },
  validations: {
    products: {
      subMenu: { required },
      filter: { required },
      specification: { required },
    },

  },
  methods: {
    /**
     * Edit Color Modal
     */




    async handleSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.products.$invalid) {
        return;
      } else {
        Master.listSubmenuFiltersAndSpecificationUpdate(
            {
              listSubCategoryId : this.products.subMenu.id,
              filters : this.products.filter,
              specifications : this.products.specification
            }).then((res) => {
          console.log(res)
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 2000
          });
          this.$router.push({path: '/master-data/product-specification-filter'})

          this.showmodal = false;
        }).catch((err) => {
          // console.log(this.errorCodeMessage = err.response.data.error.code[0])
          this.$bvToast.toast(""+err.response.data.error.name[0]+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });


          // this.errorMessage = err.response.data.error.name[0];
          this.showmodal = true;
        })

      }
      this.submitted = false;
      this.products.subMenu = "";
      this.products.filter = null;
      this.products.specification = null;
    },
  }
};
</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="text-transform: uppercase !important;">

            <form @submit.prevent="handleSubmit">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="subMenuId">LIST SUB MENU *</label>
                    <multiselect v-model="products.subMenu" :options="listSubCategoryData" track-by="name" label="name" value="id" placeholder="SELECT LIST SUB MENU" :class="{ 'is-invalid': submitted && $v.products.subMenu.$error }"></multiselect>
                    <div v-if="submitted && $v.products.subMenu.$error" class="invalid-feedback">
                      <span v-if="!$v.products.subMenu.required">LIST SUB MENU IS REQUIRED</span>
                    </div>
                  </div>

                </div>
              </div>

              <div class="form-group">
                <label>SPECIFICATIONS *</label>
                <div class="row">
                  <div class="col-md-2" v-for="(specification,index) in specificationData" :key="specification.id">
                    <div class="custom-control custom-checkbox">
                      <input :id="'invalidCheck'+index" type="checkbox" :value="specification.id" v-model="products.specification" class="custom-control-input" />
                      <label
                          class="custom-control-label"
                          :for="'invalidCheck'+index"
                      >{{specification.name}}</label>
                    </div>
                  </div>
                  <div v-if="submitted && $v.products.specification.$error" class="error" style="font-size: 80%; margin-left: 12px; margin-top: 10px">
                    <span v-if="!$v.products.specification.required">SPECIFICATIONS ARE REQUIRED</span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label >FILTERS *</label>
                <div class="row">
                  <div class="col-md-2"  v-for="(filter,index) in filterData" :key="filter.id">
                    <div class="custom-control custom-checkbox">
                      <input :id="'invalidCheck_filter'+index" type="checkbox" v-bind:value="filter.id" v-model="products.filter"   class="custom-control-input" />
                      <label
                          class="custom-control-label"
                          :for="'invalidCheck_filter'+index"
                      >{{filter.name}}</label>

                    </div>
                  </div>

                </div>
                <div v-if="submitted && $v.products.filter.$error" class="error" style="font-size: 80%; margin-top: 10px">
                  <span v-if="!$v.products.filter.required">FILTERS ARE REQUIRED</span>
                </div>
              </div>


              <div class="text-right">
                <button type="submit" class="btn btn-success">UPDATE</button>
                <router-link to="/master-data/product-specification-filter"><b-button class="ml-1 btn btn-dark">BACK</b-button></router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>